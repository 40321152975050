import axios from 'axios'

export async function FetchProducts() {
  const stats = await axios.get(`/api/points/products`, { headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } })
  return stats
}

export async function FetchStats() {
  const stats = await axios.get(`/api/points/stats`, { headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } })
  return stats
}

export async function FetchLogs() {
  const logs = await axios.get(`/api/points/logs`, { headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } })
  return logs
}

export async function AddStats(user) {
  const res = await axios.post(`/api/points/add/stats`, {
    user: user
  })

  return res
}

export async function UpdateStats(id, { points, gold }) {
  const res = await axios.post(`/api/points/update/stats/${id}`, {
    points: points,
    gold: gold
  })

  return res
}

export async function DeleteStats(id) {
  const res = await axios.post(`/api/points/delete/stats/${id}`, {})

  return res
}

export async function AddProduct(title, pointPrice, isGoldCurrency) {
  const res = await axios.post(`/api/points/add/product`, {
    pointPrice,
    title,
    isGoldCurrency
  })

  return res
}

export async function UpdateProduct(id, title, pointPrice, isGoldCurrency) {
  const res = await axios.post(`/api/points/update/product/${id}`, {
    pointPrice,
    title,
    isGoldCurrency
  })

  return res
}

export async function UploadProductImageFile(id, file) {
  let formData = new FormData();
  formData.append('file', file, file.name);

  const res = await axios.post(`/api/points/product/picture/upload/${id}`, formData)

  return res
}

export async function FetchMyStats() {
  const res = await axios.get(`/api/points/mystats`, { headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' } })
  return res
}

export async function RedeemPoints(pointProductId) {
  const res = await axios.post(`/api/points/redeem/product/${pointProductId}`)

  return res
}

export async function FetchRedeems() {
  const res = await axios.get(`/api/points/redeems?timestamp=${new Date().getTime()}`)

  return res
}

export async function SaveProductOrder(pointProducts) {
  const res = await axios.post(`/api/points/changeProductOrder`, { pointProducts })

  return res
}

export async function ShopProducts() {
  const res = await axios.get(`/api/points/shop`)

  return res
}

export async function ToggleProduct(id, toggle = true) {
  const res = await axios.post(`/api/points/toggleProduct/${id}`, { toggle })

  return res
}

export async function GetMotd() {
  const res = await axios.get(`/api/points/motd`)

  return res
}

export async function UpdateMotd(motd) {
  const res = await axios.post(`/api/points/motd`, { motd })

  return res
}