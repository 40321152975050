import axios from 'axios'

export async function AuthLogin(username, password) {

    const response = await axios.post(`/api/user/login`, { username: username, password: password })

    return [response, response.status];
}

export async function GetUser(id = null) {
    if (id) {
        return await GetSpecificUser(id)
    }
    const response = await fetch(`/api/user/getUser`)
    return response.json();
}

async function GetSpecificUser(id) {
    const response = await fetch(`/api/user/user/${id}`)
    return response.json();
}

export async function GetSeller(sellerId) {
    const response = await axios.get(`/api/user/seller/${sellerId}`);
    return response;
}

export async function UpdateMyPassword(id, password, repeatPassword) {
    const response = await axios.post(`/api/user/resetProfilePassword`,
        {
            id: id,
            password: password,
            repeatPassword: repeatPassword
        }
    )

    return response;
}

export async function UpdateUserPermissions(id, permissions) {
    const response = await axios.post(`/api/user/updatePermissions/${id}`, { permissions: permissions })
    return response
}

export async function GetUserList() {
    const response = axios.get(`/api/user/list`);
    return response;
}

export async function GetAllUsers() {
    const response = axios.get(`/api/user/all`);
    return response;
}

export async function GetSellerList() {
    const response = axios.get(`/api/user/sellerlist`);
    return response;
}

export async function AuthLogout() {
    const response = await axios.get('/api/user/logout');
}

export async function RegisterUser(username, password, email) {

    const load = {
        username: username,
        password: password,
        email: email,
        RegisteredDate: new Date().toJSON().slice(0, 10).split('-').reverse().join('-')
    }

    const response = await axios.post('/api/user/register', load);
    return response;
}

export async function RegisterSeller(username, password, email, productList) {

    const load = {
        username: username,
        password: password,
        email: email,
        productList: productList.map((value, index) => {
            return { product: value.value }
        }),
        RegisteredDate: new Date().toJSON().slice(0, 10).split('-').reverse().join('-')
    }

    const response = await axios.post('/api/user/sellerregister', load);
    return response;
}

export async function UpdateSeller(id, productList) {

    const load = {
        productList: productList.map((p, index) => {
            return { product: p.value }
        })
    }

    const response = await axios.post(`/api/user/sellerupdate/${id}`, load);
    return response;
}

export async function CheckUsername(username) {

    const response = await axios.get('/api/user/usernameExist/' + username)
    return response;
}

export async function Checkmail(mail) {

    const response = await axios.get('/api/user/mailExist/' + mail);
    return response;
}

export async function UploadProfilePicture(userId, file) {
    let formData = new FormData();
    formData.append('file', file, file.name);

    const response = await axios.post(`/api/user/picture/upload/${userId}`, formData)

    return response;
}

export async function UpdateUserAccess(userId, users) {
    const response = await axios.post(`/api/user/userAccess/${userId}`, { userAccess: users })

    return response
}

export async function UpdateProductAccess(userId, products) {

    const load = {
        productList: products.map((p, index) => {
            return { product: p.value }
        })
    }

    const response = await axios.post(`/api/user/productAccess/${userId}`, load)

    return response
}

export async function ActivateUser(userId) {
    const response = await axios.post(`/api/user/activate/${userId}`)

    return response
}

export async function DeactivateUser(userId) {
    const response = await axios.post(`/api/user/deactivate/${userId}`)

    return response
}

export async function SaveFilter(filter) {
    const response = await axios.post(`/api/user/savefilters`, filter)

    return response
}