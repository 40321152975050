import { CButton, CInput, CLabel, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import React, { useContext, useState } from "react";

export const AlertContext = React.createContext({
  active: false,
  title: "No title",
  text: "No text",
  static: false,
  cb: async (areYouSure = "") => { },
  AddAlert: (title, text, staticBackdrop, callback, withInput = null, onCancel = null) => { },
  RemoveAlert: () => { }
})

export function AlertUI() {
  const { alert, RemoveAlert } = useAlertContext();
  const [loading, setLoading] = useState(false)
  const [inputCheck, setInputCheck] = useState('')

  const handleRemoveAlert = async (ev) => {
    ev.preventDefault();
    console.log(alert)
    await alert?.onCancel?.()
    RemoveAlert();
  }

  return (
    <>
      {alert && <>
        <CModal className={"d-flex align-items-center pb-5"} closeOnBackdrop={alert.static} show={alert.active} onClose={RemoveAlert} backdrop={alert.backdrop}>
          <CModalHeader>
            <CModalTitle>{alert.title}</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {alert.text}
            {!!alert.withInput && <>
              <br />
              <CLabel className="font-weight-bold" htmlFor="areYouSure">Skriv: '{alert.withInput}' for at bekræfte.</CLabel>
              <CInput id="areYouSure" value={inputCheck} onChange={ev => setInputCheck(ev.currentTarget.value)} />
            </>}
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={handleRemoveAlert}>
              Annuller
            </CButton>
            <CButton disabled={!!alert.withInput && alert.withInput !== inputCheck} color="primary"
              onClick={async () => {
                setLoading(true)
                await alert.cb(!!alert.withInput ? inputCheck : null);
                setLoading(false)
                RemoveAlert();
                setInputCheck('')
              }}
            >Ja, jeg er sikker
              {loading && (
                <>
                  <div style={{ height: "1rem", width: "1rem" }} class="ml-2 spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </>
              )}
            </CButton>
          </CModalFooter>
        </CModal>
      </>}
    </>
  )
}

export const useAlertContext = () => {
  return useContext(AlertContext);
}

export function AlertConfirmProvider({ children }) {
  const [alert, setAlert] = useState(null);

  const SetAlertControl = (title, text, backdrop, callback, withInput = null, onCancel) => {
    setAlert({
      active: true,
      title: title,
      text: text,
      backdrop: backdrop,
      cb: callback,
      withInput: withInput,
      onCancel: onCancel
    })
  }
  const removeAlert = () => setAlert(null);

  const contextValue = {
    alert,
    AddAlert: (title, text, backdrop, callback, withInput, onCancel) => SetAlertControl(title, text, backdrop, callback, withInput, onCancel),
    RemoveAlert: () => removeAlert()
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  )
}