import { createStore } from 'redux';

const initialState = {
  sidebarShow: 'responsive',
  user: JSON.parse(localStorage.getItem('token')) ?? { loggedIn: false, permission: {}, username: '', email: '', usertype: '', impersonation: null },
  integrations: JSON.parse(localStorage.getItem("integrations")) ?? {}
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'sidebar':
      return { ...state, sidebarShow: action.payload }
    case 'integrations':
      localStorage.setItem("integrations", JSON.stringify(action.payload))
      return { ...state, integrations: action.payload }
    case 'login':
      localStorage.setItem('token', JSON.stringify(action.payload));
      return { ...state, user: action.payload }
    case 'logout':
      localStorage.setItem('token', JSON.stringify({ loggedIn: false, permission: {}, username: '', email: '', usertype: '', impersonation: null }));
      localStorage.removeItem("integrations")
      return { ...state, integrations: {}, user: { loggedIn: false, permission: {}, username: '', email: '', usertype: '', impersonation: null } }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store