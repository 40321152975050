import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FetchMyStats } from "src/services/pointsService";

export const PointsContext = React.createContext({
  points: 0,
  gold: 0,
  allowed: false,
  refresh: async () => { },
  loading: false
})

export const usePoints = () => {
  return useContext(PointsContext)
}

export function PointsProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const [points, setPoints] = useState({ points: 0, gold: 0, allowed: false })
  const userState = useSelector(state => state.user)

  useEffect(() => {
    if (userState?.loggedIn && !points.allowed) {
      const fetchData = async () => {
        setLoading(true)
        const { data } = await FetchMyStats()
        setPoints(data)
        setLoading(false)
      }

      fetchData()
    }
  }, [userState?.loggedIn])

  const refresh = async () => {
    setLoading(true)
    const { data } = await FetchMyStats()
    setPoints(data)
    setLoading(false)
  }

  let contextValue = {
    points: points.points,
    allowed: points.allowed,
    gold: points.gold,
    refresh,
    loading
  }

  return (
    <PointsContext.Provider value={contextValue}>
      {children}
    </PointsContext.Provider>
  )
}