import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import { GetUser } from "src/services/userServices";
import { usePoints } from "./usePoints";

export const ImpersonationContext = React.createContext({
    isImpersonated: false,
    loading: false,
    impersonate: (id, usertype) => { return new Promise() },
    stop: () => { return new Promise() }
})

export const useImpersonation = () => {
    return useContext(ImpersonationContext)
}

export function ImpersonationProvider({ children }) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const userState = useSelector(state => state.user)
    let history = useHistory()
    const { refresh } = usePoints()

    const impersonate = async (userId, usertype) => {
        setLoading(true)
        try {
            const response = await axios.post(`/api/user/impersonate`, {
                userId: userId,
                usertype: usertype
            })
            if (response.status === 200) {
                const data = await GetUser()
                const userObj = {
                    username: data.user.username,
                    email: data.user.email,
                    id: data.user._id,
                    permission: data.user.permission,
                    loggedIn: true,
                    usertype: data.user.usertype,
                    impersonation: data.user.impersonation
                }
                history.replace("/")
                setLoading(false)
                dispatch({ type: 'login', payload: userObj })
                refresh()
            } else {
                console.log(JSON.stringify(response))
                setLoading(false)
            }
        } catch (err) {
            console.error(err)
            setLoading(false)
        }
    }

    const stop = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`/api/user/stopimpersonating`, {})
            if (response.status === 200) {
                const data = await GetUser()
                const userObj = {
                    username: data.user.username,
                    email: data.user.email,
                    id: data.user._id,
                    permission: data.user.permission,
                    loggedIn: true,
                    usertype: data.user.usertype,
                    impersonation: data.user.impersonation
                }
                setLoading(false)
                dispatch({ type: 'login', payload: userObj })
                refresh()
            }
        } catch (err) {
            console.error(err)
            setLoading(false)
        }
    }


    const contextValue = {
        isImpersonated: userState.impersonation !== null,
        impersonate,
        stop,
        loading
    }

    return (
        <ImpersonationContext.Provider value={contextValue}>
            {children}
        </ImpersonationContext.Provider>
    )
}